<template>
  <div
  relative

  >
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title class="mb-5">
            2. Статистика пожаров в общественных зданиях.
          </v-card-title>
          <v-card-text class="title font-weight-light d-flex justify-center">
            Распространенные причины пожаров в офисе
          </v-card-text>
        </v-col>
        <v-col
        cols="6"
        >
          <v-img
            lazy-src="img/material/fire-build-2.svg"
            max-height="100%"
            max-width="80%"
            contain
            src="img/material/fire-build-2.svg"
            >
          </v-img>
        </v-col>
        <v-col
        cols="6"
        >
          <v-tabs
            color="red"
            v-model="tab"
          >
            <v-tab class="mr-10">По вине работодателя</v-tab>
            <v-tab>По вине сотрудников</v-tab>
          </v-tabs>
          <v-tabs-items
          v-model="tab"
          >
            <v-tab-item
            vertical
            >
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/exit.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/exit.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  отсутствие эвакуационных выходов
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/smart-lock.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/smart-lock.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  заблокированные запасные выходы из здания
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/fire-extinguisher.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/fire-extinguisher.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  просроченные или отсутствующие средства пожаротушения
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/inventory.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/inventory.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  захламленные подходы к средствам пожаротушения
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/questions.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/questions.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  отсутствие сотрудников, ответственных за ПБ
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
            >
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/cigarette.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/cigarette.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  курение в неположенных местах
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/room-heater.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/room-heater.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  оставленные без присмотра нагревательные приборы
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/spray-can.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/spray-can.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  хранение в помещении легковоспламеняющихся веществ
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/socket.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/socket.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  возгорание электропроводки из-за перегрузки электросетей
                </v-card-text>
              </v-card>
              <v-card
                flat
                class="d-flex pa-3"

              >
                <v-img
                  lazy-src="img/material/welder.svg"
                  max-height="100%"
                  max-width="10%"
                  contain
                  src="img/material/welder.svg"
                >
                </v-img>
                <v-card-text class="title font-weight-light">
                  несоблюдение правил эксплуатации оборудования
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <Hint></Hint>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  components: {
    Hint
  },
  data: () => ({
    tab: null
  })
}
</script>
<style>

</style>
